import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { countries } from '../../../utils/functions';

const AdvancedSearch = () => {
  const { register, watch, setValue } = useFormContext();
  const searchClass = watch('class');
  const type = watch('type');

  const handleClassClick = (value: string) => {
    setValue('class', searchClass === value ? '' : value);
  };

  const handleTypeClick = (value: string) => {
    setValue('type', type === value ? '' : value);
  };

  return (
    <>
      <div className="search_advanced">
        <div className="search_advanced_inner">
          <div className="label">Filters</div>

          <input type="hidden" name="className" value="" />
          <input type="hidden" name="type" value="" />

          <div className="filter_fields with_buttons">
            <div className="group button_group">
              <ul className="filter_buttons">
                <li>
                  <input
                    type="radio"
                    className={clsx('filter_button_checkbox')}
                    id="books_filter"
                    value="books"
                    {...register('class')}
                    onClick={() => handleClassClick('books')}
                    checked={searchClass === 'books'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button awarded"
                    htmlFor="books_filter"
                    title="Filter by Awarded: logos in books"
                  >
                    Awarded
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="filter_button_checkbox"
                    id="ranked_filter"
                    value="ranked"
                    {...register('class')}
                    onClick={() => handleClassClick('ranked')}
                    checked={searchClass === 'ranked'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button ranked"
                    htmlFor="ranked_filter"
                    title="Filter by Jury-Ranked: highly-ranked logos"
                  >
                    Jury-Ranked
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="filter_button_checkbox"
                    id="collections_filter"
                    value="collections"
                    {...register('class')}
                    onClick={() => handleClassClick('collections')}
                    checked={searchClass === 'collections'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button collected"
                    htmlFor="collections_filter"
                    title="Filter by Collected: logos in collections"
                  >
                    Collected
                  </label>
                </li>
              </ul>
            </div>
            <div className="group button_group">
              <ul className="filter_buttons">
                <li>
                  <input
                    type="radio"
                    className="filter_button_checkbox"
                    id="typographic_filter"
                    value="typographic"
                    {...register('type')}
                    onClick={() => handleTypeClick('typographic')}
                    checked={type === 'typographic'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button typographic"
                    htmlFor="typographic_filter"
                  >
                    Typographic
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="filter_button_checkbox"
                    id="symbols_filter"
                    value="symbols"
                    {...register('type')}
                    onClick={() => handleTypeClick('symbols')}
                    checked={type === 'symbols'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button symbols"
                    htmlFor="symbols_filter"
                  >
                    Symbols
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    className="filter_button_checkbox"
                    id="combo_filter"
                    value="combo"
                    {...register('type')}
                    onClick={() => handleTypeClick('combo')}
                    checked={type === 'combo'}
                  />
                  <label
                    tabIndex={0}
                    className="filter_button combos"
                    htmlFor="combo_filter"
                  >
                    Combos
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div className="filter_fields">
            <div className="group">
              <label>Client</label>
              <input type="text" placeholder="" title="Client" {...register('client')} />
            </div>
            <div className="group">
              <label>Designer</label>
              <input
                type="text"
                placeholder=""
                title="Designer"
                {...register('designer')}
              />
            </div>
            <div className="group">
              <label>Industry</label>
              <input
                type="text"
                placeholder=""
                title="Industry"
                {...register('industry')}
              />
            </div>
            <div className="group">
              <label>Country</label>
              <select {...register('country')}>
                <option value="">Anywhere</option>
                {countries.map(country => (
                  <option key={country.value} value={country.value}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="submit_outer">
          <button type="submit">Search</button>
        </div>
      </div>
    </>
  );
};

export default AdvancedSearch;
