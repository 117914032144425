export const countries = [
  { value: 'AL', name: 'ALBANIA' },
  { value: 'DZ', name: 'ALGERIA' },
  { value: 'AD', name: 'ANDORRA' },
  { value: 'AO', name: 'ANGOLA' },
  { value: 'AI', name: 'ANGUILLA' },
  { value: 'AG', name: 'ANTIGUA & BARBUDA' },
  { value: 'AR', name: 'ARGENTINA' },
  { value: 'AM', name: 'ARMENIA' },
  { value: 'AW', name: 'ARUBA' },
  { value: 'AU', name: 'AUSTRALIA' },
  { value: 'AT', name: 'AUSTRIA' },
  { value: 'AZ', name: 'AZERBAIJAN' },
  { value: 'BS', name: 'BAHAMAS' },
  { value: 'BH', name: 'BAHRAIN' },
  { value: 'BB', name: 'BARBADOS' },
  { value: 'BY', name: 'BELARUS' },
  { value: 'BE', name: 'BELGIUM' },
  { value: 'BZ', name: 'BELIZE' },
  { value: 'BJ', name: 'BENIN' },
  { value: 'BM', name: 'BERMUDA' },
  { value: 'BT', name: 'BHUTAN' },
  { value: 'BO', name: 'BOLIVIA' },
  { value: 'BA', name: 'BOSNIA & HERZEGOVINA' },
  { value: 'BW', name: 'BOTSWANA' },
  { value: 'BR', name: 'BRAZIL' },
  { value: 'VG', name: 'BRITISH VIRGIN ISLANDS' },
  { value: 'BN', name: 'BRUNEI' },
  { value: 'BG', name: 'BULGARIA' },
  { value: 'BF', name: 'BURKINA FASO' },
  { value: 'BI', name: 'BURUNDI' },
  { value: 'KH', name: 'CAMBODIA' },
  { value: 'CM', name: 'CAMEROON' },
  { value: 'CA', name: 'CANADA' },
  { value: 'CV', name: 'CAPE VERDE' },
  { value: 'KY', name: 'CAYMAN ISLANDS' },
  { value: 'TD', name: 'CHAD' },
  { value: 'CL', name: 'CHILE' },
  { value: 'C2', name: 'CHINA' },
  { value: 'CO', name: 'COLOMBIA' },
  { value: 'KM', name: 'COMOROS' },
  { value: 'CG', name: 'CONGO - BRAZZAVILLE' },
  { value: 'CD', name: 'CONGO - KINSHASA' },
  { value: 'CK', name: 'COOK ISLANDS' },
  { value: 'CR', name: 'COSTA RICA' },
  { value: 'CI', name: 'CÔTE D’IVOIRE' },
  { value: 'HR', name: 'CROATIA' },
  { value: 'CY', name: 'CYPRUS' },
  { value: 'CZ', name: 'CZECH REPUBLIC' },
  { value: 'DK', name: 'DENMARK' },
  { value: 'DJ', name: 'DJIBOUTI' },
  { value: 'DM', name: 'DOMINICA' },
  { value: 'DO', name: 'DOMINICAN REPUBLIC' },
  { value: 'EC', name: 'ECUADOR' },
  { value: 'EG', name: 'EGYPT' },
  { value: 'SV', name: 'EL SALVADOR' },
  { value: 'ER', name: 'ERITREA' },
  { value: 'EE', name: 'ESTONIA' },
  { value: 'ET', name: 'ETHIOPIA' },
  { value: 'FK', name: 'FALKLAND ISLANDS' },
  { value: 'FO', name: 'FAROE ISLANDS' },
  { value: 'FJ', name: 'FIJI' },
  { value: 'FI', name: 'FINLAND' },
  { value: 'FR', name: 'FRANCE' },
  { value: 'GF', name: 'FRENCH GUIANA' },
  { value: 'PF', name: 'FRENCH POLYNESIA' },
  { value: 'GA', name: 'GABON' },
  { value: 'GM', name: 'GAMBIA' },
  { value: 'GE', name: 'GEORGIA' },
  { value: 'DE', name: 'GERMANY' },
  { value: 'GI', name: 'GIBRALTAR' },
  { value: 'GR', name: 'GREECE' },
  { value: 'GL', name: 'GREENLAND' },
  { value: 'GD', name: 'GRENADA' },
  { value: 'GP', name: 'GUADELOUPE' },
  { value: 'GT', name: 'GUATEMALA' },
  { value: 'GN', name: 'GUINEA' },
  { value: 'GW', name: 'GUINEA-BISSAU' },
  { value: 'GY', name: 'GUYANA' },
  { value: 'HN', name: 'HONDURAS' },
  { value: 'HK', name: 'HONG KONG SAR CHINA' },
  { value: 'HU', name: 'HUNGARY' },
  { value: 'IS', name: 'ICELAND' },
  { value: 'IN', name: 'INDIA' },
  { value: 'ID', name: 'INDONESIA' },
  { value: 'IE', name: 'IRELAND' },
  { value: 'IL', name: 'ISRAEL' },
  { value: 'IT', name: 'ITALY' },
  { value: 'JM', name: 'JAMAICA' },
  { value: 'JP', name: 'JAPAN' },
  { value: 'JO', name: 'JORDAN' },
  { value: 'KZ', name: 'KAZAKHSTAN' },
  { value: 'KE', name: 'KENYA' },
  { value: 'KI', name: 'KIRIBATI' },
  { value: 'KW', name: 'KUWAIT' },
  { value: 'KG', name: 'KYRGYZSTAN' },
  { value: 'LA', name: 'LAOS' },
  { value: 'LV', name: 'LATVIA' },
  { value: 'LS', name: 'LESOTHO' },
  { value: 'LI', name: 'LIECHTENSTEIN' },
  { value: 'LT', name: 'LITHUANIA' },
  { value: 'LU', name: 'LUXEMBOURG' },
  { value: 'MK', name: 'MACEDONIA' },
  { value: 'MG', name: 'MADAGASCAR' },
  { value: 'MW', name: 'MALAWI' },
  { value: 'MY', name: 'MALAYSIA' },
  { value: 'MV', name: 'MALDIVES' },
  { value: 'ML', name: 'MALI' },
  { value: 'MT', name: 'MALTA' },
  { value: 'MH', name: 'MARSHALL ISLANDS' },
  { value: 'MQ', name: 'MARTINIQUE' },
  { value: 'MR', name: 'MAURITANIA' },
  { value: 'MU', name: 'MAURITIUS' },
  { value: 'YT', name: 'MAYOTTE' },
  { value: 'MX', name: 'MEXICO' },
  { value: 'FM', name: 'MICRONESIA' },
  { value: 'MD', name: 'MOLDOVA' },
  { value: 'MC', name: 'MONACO' },
  { value: 'MN', name: 'MONGOLIA' },
  { value: 'ME', name: 'MONTENEGRO' },
  { value: 'MS', name: 'MONTSERRAT' },
  { value: 'MA', name: 'MOROCCO' },
  { value: 'MZ', name: 'MOZAMBIQUE' },
  { value: 'NA', name: 'NAMIBIA' },
  { value: 'NR', name: 'NAURU' },
  { value: 'NP', name: 'NEPAL' },
  { value: 'NL', name: 'NETHERLANDS' },
  { value: 'NC', name: 'NEW CALEDONIA' },
  { value: 'NZ', name: 'NEW ZEALAND' },
  { value: 'NI', name: 'NICARAGUA' },
  { value: 'NE', name: 'NIGER' },
  { value: 'NG', name: 'NIGERIA' },
  { value: 'NU', name: 'NIUE' },
  { value: 'NF', name: 'NORFOLK ISLAND' },
  { value: 'NO', name: 'NORWAY' },
  { value: 'OM', name: 'OMAN' },
  { value: 'PW', name: 'PALAU' },
  { value: 'PA', name: 'PANAMA' },
  { value: 'PG', name: 'PAPUA NEW GUINEA' },
  { value: 'PY', name: 'PARAGUAY' },
  { value: 'PE', name: 'PERU' },
  { value: 'PH', name: 'PHILIPPINES' },
  { value: 'PN', name: 'PITCAIRN ISLANDS' },
  { value: 'PL', name: 'POLAND' },
  { value: 'PT', name: 'PORTUGAL' },
  { value: 'QA', name: 'QATAR' },
  { value: 'RE', name: 'RÉUNION' },
  { value: 'RO', name: 'ROMANIA' },
  { value: 'RU', name: 'RUSSIA' },
  { value: 'RW', name: 'RWANDA' },
  { value: 'WS', name: 'SAMOA' },
  { value: 'SM', name: 'SAN MARINO' },
  { value: 'ST', name: 'SÃO TOMÉ & PRÍNCIPE' },
  { value: 'SA', name: 'SAUDI ARABIA' },
  { value: 'SN', name: 'SENEGAL' },
  { value: 'RS', name: 'SERBIA' },
  { value: 'SC', name: 'SEYCHELLES' },
  { value: 'SL', name: 'SIERRA LEONE' },
  { value: 'SG', name: 'SINGAPORE' },
  { value: 'SK', name: 'SLOVAKIA' },
  { value: 'SI', name: 'SLOVENIA' },
  { value: 'SB', name: 'SOLOMON ISLANDS' },
  { value: 'SO', name: 'SOMALIA' },
  { value: 'ZA', name: 'SOUTH AFRICA' },
  { value: 'KR', name: 'SOUTH KOREA' },
  { value: 'ES', name: 'SPAIN' },
  { value: 'LK', name: 'SRI LANKA' },
  { value: 'SH', name: 'ST. HELENA' },
  { value: 'KN', name: 'ST. KITTS & NEVIS' },
  { value: 'LC', name: 'ST. LUCIA' },
  { value: 'PM', name: 'ST. PIERRE & MIQUELON' },
  { value: 'VC', name: 'ST. VINCENT & GRENADINES' },
  { value: 'SR', name: 'SURINAME' },
  { value: 'SJ', name: 'SVALBARD & JAN MAYEN' },
  { value: 'SZ', name: 'SWAZILAND' },
  { value: 'SE', name: 'SWEDEN' },
  { value: 'CH', name: 'SWITZERLAND' },
  { value: 'TW', name: 'TAIWAN' },
  { value: 'TJ', name: 'TAJIKISTAN' },
  { value: 'TZ', name: 'TANZANIA' },
  { value: 'TH', name: 'THAILAND' },
  { value: 'TG', name: 'TOGO' },
  { value: 'TO', name: 'TONGA' },
  { value: 'TT', name: 'TRINIDAD & TOBAGO' },
  { value: 'TN', name: 'TUNISIA' },
  { value: 'TM', name: 'TURKMENISTAN' },
  { value: 'TC', name: 'TURKS & CAICOS ISLANDS' },
  { value: 'TV', name: 'TUVALU' },
  { value: 'UG', name: 'UGANDA' },
  { value: 'UA', name: 'UKRAINE' },
  { value: 'AE', name: 'UNITED ARAB EMIRATES' },
  { value: 'GB', name: 'UNITED KINGDOM' },
  { value: 'US', name: 'UNITED STATES' },
  { value: 'UY', name: 'URUGUAY' },
  { value: 'VU', name: 'VANUATU' },
  { value: 'VA', name: 'VATICAN' },
  { value: 'VE', name: 'VENEZUELA' },
  { value: 'VN', name: 'VIETNAM' },
  { value: 'WF', name: 'WALLIS & FUTUNA' },
  { value: 'YE', name: 'YEMEN' },
  { value: 'ZM', name: 'ZAMBIA' },
  { value: 'ZW', name: 'ZIMBABWE' },
];

export interface IComments {
  avatar_url: string;
  company: string;
  first_name: string;
  last_name: string;
  logo_comment_id: number;
  designer_id: number;
  slug: string;
  comment: string;
  date_added: string;
}

export const orderCommentsByDate = (object: IComments[]) => {
  if (object) {
    return object.sort((a, b) => {
      const dateA = new Date(a.date_added);
      const dateB = new Date(b.date_added);
      return dateB.getTime() - dateA.getTime();
    });
  }
  return [];
};

export function getTimeDifference(commentDate: string): string {
  const commentDateTime = new Date(commentDate);
  const currentDate = new Date();

  const timeDifferenceInMilliseconds = currentDate.getTime() - commentDateTime.getTime();
  const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
  const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
  const timeDifferenceInHours = timeDifferenceInMinutes / 60;
  const timeDifferenceInDays = timeDifferenceInHours / 24;
  const timeDifferenceInWeeks = timeDifferenceInDays / 7;
  const timeDifferenceInYears = timeDifferenceInDays / 365;

  const timeUnits = [
    { value: timeDifferenceInYears, unit: 'y' },
    { value: timeDifferenceInWeeks, unit: 'w' },
    { value: timeDifferenceInDays, unit: 'd' },
    { value: timeDifferenceInHours, unit: 'h' },
    { value: timeDifferenceInMinutes, unit: 'm' },
  ];

  const result = timeUnits.find(unit => unit.value >= 1);

  if (result) {
    const { value, unit } = result;
    return `${Math.floor(value)}${unit}`;
  } else {
    return 'Just now';
  }
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);

  const day = date.getDate().toString();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  const hours = (date.getHours() % 12 || 12).toString();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `Published ${month} ${day}, ${year} · ${hours}:${minutes} ${ampm}`;
}

export const getDate = (
  dateString: string,
  format: 'long' | 'short' = 'short'
): string => {
  if (dateString.trim() === '') {
    return dateString;
  }

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    if (format === 'long') {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
    } else {
      return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(
        date
      );
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

export const fullUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
};
export const formatUrl = (url: string) => {
  let strippedUrl = url.replace(/^https?:\/\//, '');
  strippedUrl = strippedUrl.replace(/^www\./, '');
  strippedUrl = strippedUrl.replace(/\/$/, '');

  return strippedUrl;
};

export const deleteValidation = (upload_date: string) => {
  const uploadDate = new Date(upload_date);
  const now = new Date();
  const timeDifference = now.getTime() - uploadDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  if (hoursDifference < 48) {
    return true;
  }
  return false;
};
