import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { joinSchema } from '../schemas/join.schema';
import { IJoin } from '../types/join.types';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import axios from '../api';
import useAuth from '../hooks/useAuth';
import useToast from '../hooks/useToast';
import { countries } from '../utils/functions';

// Extend IJoin locally to include the notes field
interface IJoinWithNotes extends IJoin {
  notes?: string;
}

const Join = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const toast = useToast();
  const [terms, setTerms] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState<string | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    control,
  } = useForm<IJoin>({ resolver: yupResolver(joinSchema) });

  // Set userData with Partial<IJoinWithNotes> to allow empty initial state
  const [userData, setUserData] = useState<Partial<IJoinWithNotes>>({});

  useEffect(() => {
    document.title = 'Join LogoLounge - Logo Design Inspiration & Competition - Work';

    // Extract the 'aff' parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const aff = params.get('aff');
    if (aff) setAffiliateCode(aff);

    // If the user is already authenticated, redirect to checkout
    if (isAuthenticated) navigate('/checkout');
  }, [isAuthenticated, navigate]);

  const handleContinue = async () => {
    const isPasswordValid = await trigger('password');
    if (isPasswordValid && terms) return setShowForm(true);
    return setShowForm(false);
  };

  const handleChange = (
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Only keep numbers, spaces, dashes, plus signs, and parentheses
    const cleanedValue = event.target.value.replace(/[^\d\s\-+()]/g, '');

    // Limit the total length to 20 characters to prevent extremely long inputs
    const truncatedValue = cleanedValue.slice(0, 20);

    event.target.value = truncatedValue;
    onChange(event);
  };

  const handleJoin = (data: IJoin) => {
    // Create a new userData object that includes the affiliate code
    const userDataWithNotes: IJoinWithNotes = { ...data };
    if (affiliateCode) {
      userDataWithNotes.notes = affiliateCode;
    }

    axios
      .post('/api/register/validate', { user: userDataWithNotes, isNew: true })
      .then(response => {
        if (response.status === 200) {
          navigate('/checkout', { state: { userData: userDataWithNotes } });
        } else {
          toast.addToast({
            type: 'error',
            message: 'Invalid User Info: ' + response.statusText,
          });
        }
      })
      .catch(error => {
        console.error('Invalid User Info: ', error?.response?.data?.error);
        toast.addToast({
          type: 'error',
          message: 'Invalid User Info: ' + error?.response.statusText,
        });
      });
  };

  return (
    <Layout className="join has_right_background">
      <main id="content">
        <figure></figure>

        <article>
          <div className="content_outer">
            <h1>Join the fun.</h1>
            <form className="form-join" onSubmit={handleSubmit(handleJoin)}>
              <input type="hidden" name="step" value="1" />

              <fieldset>
                <legend>New Account</legend>

                <div className="form-group">
                  <label className="control-label">Username</label>
                  <input className="form-control" type="text" {...register('username')} />
                </div>

                <div className="form-group">
                  <label className="control-label">Password</label>
                  <div className="password-container">
                    <input
                      className="form-control input-password"
                      type={showPassword ? 'text' : 'password'}
                      maxLength={20}
                      {...register('password')}
                    />
                    <div
                      className="icon"
                      onClick={() => setShowPassword(prevShow => !prevShow)}
                    >
                      {showPassword ? <VscEye /> : <VscEyeClosed />}
                    </div>
                  </div>
                  <span className="error_message">{errors.password?.message}</span>
                </div>

                <div
                  className="form-group checkbox_outer"
                  onClick={e => {
                    e.stopPropagation();
                    setTerms(oldState => !oldState);
                  }}
                >
                  <input
                    type="checkbox"
                    id="terms"
                    value={'1'}
                    checked={terms}
                    required
                    readOnly={true}
                  />
                  <label>
                    I agree to the{' '}
                    <a href="/terms" target="_blank">
                      Terms and Conditions
                    </a>
                    .
                  </label>
                </div>
              </fieldset>

              <div className="button_outer">
                <p>
                  <a href="login">Already a member?</a>
                </p>

                <label className="button" onClick={handleContinue}>
                  Continue
                </label>
              </div>

              {showForm && (
                <>
                  <fieldset>
                    <legend>About You</legend>

                    <div className="form-group-wrap--name">
                      <div className="form-group">
                        <label className="control-label">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('first_name')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('last_name')}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Company</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register('company')}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        {...register('email')}
                      />
                    </div>

                    <div className="form-group">
                      <label className="control-label">Phone</label>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="tel"
                            {...field}
                            onChange={event => handleChange(field.onChange, event)}
                            placeholder="Enter phone number"
                            maxLength={20}
                          />
                        )}
                      />
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>Your Whereabouts</legend>

                    <div className="form-group-wrap--street-suite">
                      <div className="form-group">
                        <label className="control-label">Street Address</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address1')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Suite</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address2')}
                        />
                      </div>
                    </div>

                    <div className="form-group-wrap--city-state-zip">
                      <div className="form-group">
                        <label className="control-label">City</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('city')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">State/Province</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('state')}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Postal Code</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('zip')}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Country/Region</label>
                      <select className="form-control" {...register('country')}>
                        <option value="">Choose a country...</option>
                        {countries.map(country => (
                          <option key={country.value} value={country.value}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      <p className="form-country-message">
                        If your country is not in the list, please contact{' '}
                        <a href="mailto:info@logolounge.com" target="_blank">
                          info@logolounge.com
                        </a>
                      </p>
                    </div>

                    <div className="button_outer">
                      <button type="submit">Create Account</button>
                    </div>
                  </fieldset>
                </>
              )}
            </form>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Join;
